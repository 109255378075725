var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-center"},[_vm._v("Mijn orders")]),_c('v-data-table',{attrs:{"items":_vm.orders,"headers":_vm.headers,"server-items-length":_vm.ordersLength,"options":_vm.options,"footer-props":{
      showFirstLastPage: true,
      'items-per-page-options': [10, 25, 50, 100]
    },"item-key":"_id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":_vm.orderStatuses[item.status].color}},[_vm._v(_vm._s(_vm.orderStatuses[item.status].text))])]}},{key:"item.date",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toDateString(item.date))+" ")]}},{key:"item.grossTotal",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toCurrency(item.grossTotal))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }