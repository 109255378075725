<template>
  <v-card>
    <v-card-title class="d-flex justify-center">Mijn orders</v-card-title>
    <v-data-table
      :items="orders"
      :headers="headers"
      :server-items-length="ordersLength"
      :options.sync="options"
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [10, 25, 50, 100]
      }"
      item-key="_id"
    >
      <template v-slot:[`item.status`]="{ item }">
        <v-chip label :color="orderStatuses[item.status].color">{{
          orderStatuses[item.status].text
        }}</v-chip>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ toDateString(item.date) }}
      </template>
      <template v-slot:[`item.grossTotal`]="{ item }">
       {{toCurrency(item.grossTotal)}}
    </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { toDateString, toCurrency } from "../js/functions"
export default {
  name: "myOrders",
  data() {
    return {
      ordersLength: 0,
      headers: [
        { text: "Status", value: "status" },
        { text: "Datum", value: "date" },
        { text: "Ordernr", value: "orderNumber" },
        { text: "Totaal", value: "grossTotal" }
      ],
      options: {
        itemsPerPage: 25,
        page: 1,
        sortBy: ["orderNumber"],
        sortDesc: [true]
      }
    }
  },
  methods: {
    toDateString(date) {
      return toDateString(date)
    },
    toCurrency(number) {
      return toCurrency(number)
    },
    async fetchOrders() {
      try {
        let response = await this.$store.dispatch("fetchGET", {
          type: "clientOrders",
          data: {
            clientNumber: this.$store.state.client.clientNumber,
            options: this.options
          }
        })
        console.log(response)
        this.ordersLength = response[0].length.length
          ? response[0].length[0].length
          : 0
      } catch (e) {
        console.error(e)
      }
    }
  },
  watch: {
    options(newValue, oldValue) {
      let props = ["page", "itemsPerPage", "sortBy", "sortDesc"]
      for (let prop of props) {
        if (oldValue[prop] !== newValue[prop]) return this.fetchOrders()
      }
    }
  },
  computed: {
    orders() {
      return this.$store.state.orders
    },
    orderStatuses() {
      return this.$store.state.settings.orderStatuses
    }
  },
  mounted() {
    this.fetchOrders()
  }
}
</script>

<style scoped>
.v-application .red, .v-application .orange, .v-application .light-green, .v-application .black{
  color: white;
  border-color: #013FBA !important;
}
::v-deep .v-badge__badge{ 
  color: black !important;
}
</style>